import Form from '../forms/forms';

export const CONDITION_CSS = {
  ROOT: 'condition',
  NODE: 'condition-node',
  OPTIONS: 'condition-options',
  OPTION: 'condition-option',
  OPTION_SELECTED: 'condition-option--selected',
};

const CONDITION_ATTR = {
  HASH: 'data-omq-hash',
  VALUE: 'data-omq-value',
};

/**
 * Handle click/selection of condition option.
 *
 * @param {HTMLElement} option - condition option element
 */
const handleConditionOptionClick = (option: HTMLElement) => {
  // get hash value of selected option
  const conditionNodeHash = option.getAttribute(CONDITION_ATTR.HASH);

  // get the closest parent condition
  const parentCondition = option.closest(`.${CONDITION_CSS.ROOT}`);
  if (parentCondition == null || !(parentCondition instanceof HTMLElement)) {
    console.error('Condition option is not a child of a condition.');
    return;
  }

  // get element that holds all options for current condition
  const conditionOptions = option.parentElement;
  if (conditionOptions == null) {
    console.error('Missing condition options.');
    return;
  }

  // unselect all options
  Array.from(conditionOptions.querySelectorAll(`.${CONDITION_CSS.OPTION}`)).forEach((option) =>
    option.classList.remove(CONDITION_CSS.OPTION_SELECTED),
  );

  // add select style to selected condition option
  option.classList.add(CONDITION_CSS.OPTION_SELECTED);

  // hide all condition nodes
  Form.disableFormElementForCondition(parentCondition);

  // get condition value element (hidden input)
  const conditionValueElement = Array.from(parentCondition.children).find(
    (child) => child.tagName === 'INPUT',
  );

  // update hidden input field with value from selected option
  // & mark as active (will be sent to server on submit)
  if (conditionValueElement != null && conditionValueElement instanceof HTMLInputElement) {
    conditionValueElement.value = option.getAttribute(CONDITION_ATTR.VALUE) || '';
  }

  // hide all condition nodes
  Array.from(parentCondition.querySelectorAll(`.${CONDITION_CSS.NODE}`)).forEach(
    (node: HTMLElement) => (node.style.display = 'none'),
  );

  // if condition hash is not set,
  // there is no condition node to open
  if (conditionNodeHash == null || conditionNodeHash === '') {
    return;
  }

  // get condition node matching the selection
  const selectedNode = Array.from(parentCondition.children).find((child) =>
    child.matches(`.${CONDITION_CSS.NODE}[id="${conditionNodeHash}"]`),
  ) as HTMLElement;

  if (selectedNode == null) {
    console.error('Condition node not found for hash.', conditionNodeHash);
    return;
  }

  // enable form element of selected condition node
  Form.enableFormElementsForConditionNode(selectedNode);

  // make condition node visible
  selectedNode.style.display = 'block';
  selectedNode.scrollIntoView({
    behavior: 'smooth',
    block: 'start',
    inline: 'nearest',
  });

  restoreSelectionForSelectedNode(selectedNode);
};

/**
 * Restore selected nodes after changing node options.
 * Go through all condition nodes of the passed node, and check if there was a previous selection
 * and re-select elements.
 *
 * @param element - selected node
 */
const restoreSelectionForSelectedNode = (element: HTMLElement): void => {
  // get all conditions within condition node
  const conditions = Array.from(element.children).filter((child) =>
    child.matches(`.omq-answer-item.${CONDITION_CSS.ROOT}`),
  );
  if (conditions.length === 0) {
    return;
  }

  // go through all conditions
  conditions.forEach((condition) => {
    // get condition options
    const conditionOptions = Array.from(condition.children).find((child) =>
      child.matches('.' + CONDITION_CSS.OPTIONS),
    );

    if (conditionOptions == null) {
      return;
    }

    // check if there is a selected option
    const selectedOption = Array.from(conditionOptions.children).find((child) =>
      child.matches('.' + CONDITION_CSS.OPTION_SELECTED),
    );

    // nothing selected, nothing to do
    if (selectedOption == null) {
      return;
    }

    // get hash of selected node
    const conditionNodeHash = selectedOption.getAttribute(CONDITION_ATTR.HASH);

    // if condition hash is not set,
    // there is no condition node to open
    if (conditionNodeHash == null || conditionNodeHash === '') {
      return;
    }

    // get condition node matching the selection
    const selectedNode = Array.from(condition.children).find((child) =>
      child.matches(`.${CONDITION_CSS.NODE}[id="${conditionNodeHash}"]`),
    ) as HTMLElement;

    if (selectedNode == null) {
      return;
    }

    // make condition node visible
    selectedNode.style.display = 'block';

    // restore selection for sub conditions
    restoreSelectionForSelectedNode(selectedNode);
  });
};

/**
 * Init conditional answer inside article or help/contact answer.
 *
 * @param {HTMLElement} element - element that holds answer document
 *
 * @returns {(function(): void)|*} - clean up function to remove all event handlers
 */
export const initConditionalAnswers = (element: HTMLElement): (() => void) => {
  // create click handler
  // check if condition option has been clicked
  // and run option click handler.
  const clickHandler = (evt: MouseEvent) => {
    // $FlowExpectedError - target is missing in mouse event
    const option: HTMLElement = evt.target as HTMLElement;

    if (option.matches(`.${CONDITION_CSS.OPTION}`)) {
      handleConditionOptionClick(option);
    }
  };

  // disable all form elements that are part of conditions and not visible
  Array.from(element.querySelectorAll('.' + CONDITION_CSS.ROOT)).forEach((condition) => {
    // hide all condition nodes
    Form.disableFormElementForCondition(condition as HTMLElement);
  });

  // add event handler
  element.addEventListener('click', clickHandler);

  // return clean up function
  return () => {
    element.removeEventListener('click', clickHandler);
  };
};
