import { useEffect } from 'react';

import type { IAnswerCodeElement } from '@omq/answer-code-element';

/**
 * Initialize code element for given code element.
 *
 * @param container - HTML code element.
 *
 *  @returns {Promise<Array<IAnswerCodeElement>>}
 */
async function initializeAnswerEditors(container: HTMLElement): Promise<Array<IAnswerCodeElement>> {
  // get all code elements
  const codeElements = container.querySelectorAll('.omq-answer-item.source');

  // return empty if there are no code elements
  if (codeElements.length === 0) {
    return [];
  }

  // import code element component
  const { AnswerCodeElement } = await import(
    /* webpackChunkName: "code-editor" */ '@omq/answer-code-element'
  );

  // initialize code element for all elements
  return Array.from(codeElements).map((codeElement) => {
    return new AnswerCodeElement(codeElement as HTMLElement);
  });
}

/**
 * Clean up editor instances
 *
 * @param editors
 */
function cleanUp(editors) {
  editors.forEach((editor) => editor.destroy());
}

/**
 * Hook to add code editor to all code elements.
 *
 * @param ref - reference to html element that contains the answer
 */
export function useAnswerCodeEditor(ref: { current: HTMLElement | null }) {
  useEffect(() => {
    if (ref.current == null) {
      return;
    }

    // add flag to detect if component is still mounted
    let isMounted = true;

    // store editor instances
    let editors = [];

    // initialize all editors
    initializeAnswerEditors(ref.current).then((result) => {
      // if component is not mounted anymore,
      // clean up
      if (!isMounted) {
        cleanUp(result);
        return;
      }

      // store editors for clean up later
      editors = result;
    });

    // clean up when component gets unmounted
    return () => {
      // update flag
      isMounted = false;

      // clean up editor instances
      cleanUp(editors);
    };
  }, [ref]);
}
