import { useRef, useImperativeHandle, useEffect, forwardRef } from 'react';

import { useConfigContext } from '@omq/shared';

import './search-input.less';

export type SearchInputRefType = {
  focus: () => void;
  blur: () => void;
};

/**
 * Type for component properties.
 */
type SearchInputProps = {
  /**
   * Value of text input
   */
  value: string;

  autoFocus?: boolean;

  placeholder?: string | null;

  /**
   * Handler for input changes
   */
  onChange: (searchValue: string) => void;

  /**
   * Handle key down events.
   */
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;

  /**
   * Handle focus events
   */
  onFocus?: (event: React.SyntheticEvent<HTMLInputElement>) => void;

  /**
   * Handle blur events
   */
  onBlur?: (event: React.SyntheticEvent<HTMLInputElement>) => void;

  onIconClick?: () => void;

  onCloseClick?: (() => void) | null | undefined;

  isDisabled?: boolean;

  /**
   * Child components
   */
  children?: JSX.Element;
};

/**
 * Component for Help search.
 *
 * Changes are sent to `onChange` handler.
 *
 * Component can render children with `props.children`.
 *
 * Example usage:
 *   <Search value={searchValue}
 *           onChange={handler}>
 *     <AutoComplete ... />
 *   </Search>
 *
 * @param {SearchProps} props - Component properties
 * @param {{}} ref - Reference to component
 *
 * @author Florian Walch
 * @since 9.2
 *
 * @returns {JSX.Element}
 */
const SearchInput = function (props: SearchInputProps, ref): JSX.Element {
  const {
    value,
    placeholder,
    autoFocus,
    isDisabled,
    onChange,
    onKeyDown,
    onFocus,
    onBlur,
    onIconClick,
    onCloseClick,
  } = props;

  const config = useConfigContext();
  const inputRef = useRef<HTMLInputElement | null>(null);

  // create public interface for parent
  useImperativeHandle(ref, () => ({
    focus: () => {
      /* istanbul ignore else */
      if (inputRef.current != null) {
        inputRef.current.focus();
      }
    },
    blur: () => {
      /* istanbul ignore else */
      if (inputRef.current != null) {
        inputRef.current.blur();
      }
    },
  }));

  // focus input after render if autofocus is enabled
  useEffect(() => {
    if (autoFocus === true && inputRef.current != null) {
      inputRef.current.focus();
    }
  }, [autoFocus, inputRef]);

  /**
   * Focus into input field for clicks on the entire component.
   *
   * Use mouseDownCapture instead of click to get notified
   * before blur event. Blur event would hide auto complete.
   *
   * @param {SyntheticEvent} event - Mouse down event
   */
  function handleSearchInputClick(event: React.SyntheticEvent): void {
    // do not prevent default if event is triggered by input field itself.
    // otherwise mouse selection does not work anymore
    if (event.target === inputRef.current) {
      return;
    }

    // suppress blur event
    event.preventDefault();

    // focus into input field
    /* istanbul ignore else */
    if (inputRef.current != null) {
      inputRef.current.focus();
    }
  }

  let iconClassName = config.generateClassName('search__icon');

  // if search value is not empty, and click handler is passed
  // show close icon
  if (value !== '' && onIconClick != null) {
    iconClassName = `${iconClassName} ${config.generateClassName('search__icon--close')}`;
  }

  // add disabled style
  const className = `${config.generateClassName('search')} ${
    isDisabled === true ? config.generateClassName('search--is-disabled') : ''
  }`;

  // move cursor at the end after focus
  // quick fix for IE 11 losing cursor position after re-render
  const handleFocus = (evt) => {
    // get current input
    const input = inputRef.current;

    if (input != null) {
      // get input value
      const position = input.value.length;

      // set selection at the end of the value
      input.setSelectionRange(position, position);
    }

    // call passed focus handler
    if (onFocus != null) {
      onFocus(evt);
    }
  };

  const placeholderText = placeholder == null ? config.loc('search_placeholder') : placeholder;

  return (
    <div className={className}>
      <div
        className={config.generateClassName('search__wrapper')}
        onMouseDownCapture={handleSearchInputClick}>
        <i className={iconClassName} onClick={onIconClick} />

        <input
          aria-label={placeholderText}
          disabled={isDisabled}
          className={config.generateClassName('search__input')}
          type="text"
          autoFocus={autoFocus}
          title={placeholderText}
          placeholder={placeholderText}
          value={value}
          ref={inputRef}
          onKeyDown={onKeyDown}
          onFocus={handleFocus}
          onBlur={onBlur}
          onChange={(evt) => onChange(evt.target.value)}
        />

        {onCloseClick && (
          <i
            className={`${config.generateClassName('search__icon')} ${config.generateClassName(
              'search__icon--close',
            )}`}
            onClick={onCloseClick}
          />
        )}
      </div>
      {props.children}
    </div>
  );
};

// wrap component in forward ref
const ForwardSearchInput = forwardRef<SearchInputRefType, SearchInputProps>(SearchInput);
export { ForwardSearchInput as SearchInput };
